import React, { useEffect, useRef, useState } from 'react';
import { FaMapSigns } from 'react-icons/fa';
import WaitlistPortal from './WaitlistPortal';
import SiphonImage from '../img/Siphon.png'; // Import the background image

function HeroSection() {
  const typewriterRef = useRef(null);
  const caretRef = useRef(null);
  const [isWaitlistVisible, setIsWaitlistVisible] = useState(false);

  useEffect(() => {
    const typewriter = typewriterRef.current;
    const text = typewriter.textContent;
    typewriter.textContent = '';
    let i = 0;

    function type() {
      if (i < text.length) {
        typewriter.textContent += text.charAt(i);
        i++;
        setTimeout(type, 150);
      }
    }

    type();
  }, []);

  const handleJoinClick = () => {
    setIsWaitlistVisible(true);
  };

  const closeWaitlist = () => {
    setIsWaitlistVisible(false);
  };

  return (
    <>
      <div
        className="hero-section relative flex items-center justify-center min-h-screen bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, 0.0), rgba(25, 25, 112, 0.0)), url(${SiphonImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Hero Content */}
        <div className="hero-content text-center p-5 lg:p-20 relative z-10 max-w-full">
          <p className="hero-text font-bold mb-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
            <span className="hero-typewriter-wrapper">
              <span
                className="hero-typewriter italic text-transparent bg-clip-text bg-gradient-to-r from-[#ff6a5c] via-[#d93775] to-[#9450a8]"
                ref={typewriterRef}
              >
                Next Adventure
              </span>
              <span className="hero-caret" ref={caretRef}>
                |
              </span>
            </span>
          </p>
          <p className="hero-subtext text-base sm:text-lg md:text-xl lg:text-2xl mb-6 px-2 sm:px-0">
            Explore, connect, and support local communities with Wesafar and every connection is rewarded.
          </p>
          <div className="flex justify-center transition-all duration-1000 ease-in-out transform-gpu opacity-100 translate-y-0">
            <button
              className="hero-button flex items-center justify-center px-8 py-4 rounded-[15px] bg-gradient-to-r from-[#ff6a5c] via-[#d93775] to-[#9450a8] text-white font-bold text-lg transition-transform duration-500 ease-in-out transform-gpu hover:scale-110 shadow-lg"
              onClick={handleJoinClick}
            >
              <FaMapSigns className="mr-3" /> Join the Adventure
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 z-20 transform ${
          isWaitlistVisible ? 'translate-y-0' : '-translate-y-full'
        } transition-transform duration-500 ease-in-out`}
        style={{
          backgroundColor: '#19072cd1',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        {isWaitlistVisible && (
          <>
            {/* Close Button */}
            <button
              onClick={closeWaitlist}
              className="absolute top-4 right-4 text-white text-xl bg-red-600 p-3 rounded-full hover:bg-red-500"
            >
              &times;
            </button>
            <WaitlistPortal setIsWaitlistVisible={setIsWaitlistVisible} />
          </>
        )}
      </div>
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Muli:wght@400;700&display=swap');

        .hero-section,
        .hero-text,
        .hero-subtext,
        .hero-button {
          font-family: 'Muli', sans-serif;
        }

        .hero-subtext {
          background: linear-gradient(to right, #ffffff, #ffffff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent;
        }

        .hero-typewriter-wrapper {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          vertical-align: bottom;
          max-width: 100%;
        }

        .hero-typewriter {
          display: inline-block;
          font-size: 1.5em;
          text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.1);
          letter-spacing: 0.15em;
          white-space: normal;
          word-wrap: break-word;
        }

        .hero-caret {
          display: inline-block;
          font-size: 1.5em;
          color: white;
          animation: hero-blink-caret 0.75s step-end infinite;
          vertical-align: bottom;
        }

        @keyframes hero-blink-caret {
          from,
          to {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
        }
      `}</style>
    </>
  );
}

export default HeroSection;
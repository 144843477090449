import React, { useState, useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import t2b from '../img/t2b.webp'; // Replace with the correct image path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import WaitlistPortal from './WaitlistPortal';

const SocialEngagementSection = () => {
  const [isWaitlistVisible, setIsWaitlistVisible] = useState(false);

  useEffect(() => {
    ScrollReveal().reveal('.fade-in', {
      origin: 'top',
      distance: '50px',
      duration: 1000,
      easing: 'ease',
      reset: true,
    });
  }, []);

  const handleJoinWaitlist = () => {
    setIsWaitlistVisible(true);
  };

  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;700&display=swap');

          .about-us-section {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: center;
            max-width: 1200px;
            margin: 0 auto; /* Centers the main container */
            padding: 40px 20px; /* Adds gap from the left and right */
            border-radius: 15px;
            color: white;
          }

          .about-us-image-container {
            flex: 1; /* Takes up half of the container */
            overflow: hidden; /* Ensures the image doesn't overflow */
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
          }

          .about-us-image {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensures the image fills the space nicely */
          }

          .about-us-content {
            flex: 1; /* Takes up the other half of the container */
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            background-color: #1f1f2e;
          }

          .about-us-heading {
            font-family: 'Muli', sans-serif;
            font-size: 2.5rem;
            font-weight: bold;
            color: #56c7d3;
            margin-bottom: 10px;
          }

          .about-us-subheading {
            font-size: 2rem;
            color: #d857a6;
            font-weight: bold;
            display: block;
            margin-bottom: 20px;
          }

          .about-us-paragraph {
            font-family: 'Muli', sans-serif;
            font-size: 1.1rem;
            line-height: 1.8;
            margin-bottom: 20px;
          }

          .about-us-buttons {
            display: flex;
            gap: 20px;
            margin-top: 30px;
          }

          .about-us-button {
            padding: 15px 25px;
            font-size: 1.1rem;
            font-weight: bold;
            border: 2px solid white;
            border-radius: 10px;
            cursor: pointer;
            background: transparent;
            color: white;
            transition: all 0.3s ease;
          }

          .about-us-button:hover {
            background: #b01a62;
            color: white;
          }

          .play-button {
            background: #3c3f8f;
            border-color: #3c3f8f;
            display: flex;
            align-items: center;
          }

          .play-button:hover {
            background: #b01a62;
            color: white;
          }

          .play-icon {
            margin-left: 10px;
            font-size: 1.3rem;
          }

          @media (max-width: 768px) {
            .about-us-section {
              flex-direction: column;
            }

            .about-us-image-container {
              height: 300px; /* Adjust height for smaller screens */
              border-radius: 15px 15px 0 0;
            }

            .about-us-content {
              border-radius: 0 0 15px 15px;
              padding: 20px;
            }

            .about-us-heading {
              font-size: 2rem;
            }

            .about-us-subheading {
              font-size: 1.5rem;
            }

            .about-us-paragraph {
              font-size: 1rem;
            }
          }
        `}
      </style>
      <div className="about-us-section">
        {/* Left Column - Image */}
        <div className="about-us-image-container fade-in">
          <img src={t2b} alt="Tripper to Business" className="about-us-image" />
        </div>

        {/* Right Column - Content */}
        <div className="about-us-content fade-in">
          <h2 className="about-us-heading">
            New Travel Era
          </h2>
          <p className="about-us-paragraph">
            Bienvenue chez Wesafar, le réseau social éthique et équitable. Nous révolutionnons les interactions en ligne avec une plateforme décentralisée, sécurisée par la blockchain, qui valorise et rémunère équitablement vos contributions tout en garantissant le contrôle total de vos données. Rejoignez une communauté authentique et solidaire.
          </p>
          <div className="about-us-buttons">
            <button className="about-us-button" onClick={handleJoinWaitlist}>
              Join Waitlist
            </button>
            <button className="about-us-button play-button">
              White Paper <FontAwesomeIcon icon={faPlayCircle} className="play-icon" />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 z-20 ${
          isWaitlistVisible ? 'visible' : 'hidden'
        }`}
        style={{
          backgroundColor: '#19072cd1',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        {isWaitlistVisible && (
          <WaitlistPortal setIsWaitlistVisible={setIsWaitlistVisible} />
        )}
      </div>
    </>
  );
};

export default SocialEngagementSection;